/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Fotogalerie"}>
        <SiteHeader />

        <Column className="css-1cbl14e pb--60 pt--60" name={"uvod"} style={{"backgroundColor":"var(--color-supplementary)"}} layout={"l13"} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/139/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/139/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/139/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/139/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/139/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/139/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/139/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/139/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --left el--1 flex--top" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper className="--center pb--25 pt--40" style={{"maxWidth":""}}>
              
              <Title className="title-box fs--72" content={"<span style=\"color: rgb(255, 255, 255);\">Photo Book</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"information"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--1 flex--top" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":560}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textes, die Schriftgröße und den Zeilenabstand ändern und sogar die maximale Breite des Textes in Pixeln einstellen."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"fotogallerie-1"} layout={"l8"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--3 pb--10 pt--10" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/139/img-1_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/139/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/139/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/139/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/139/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/139/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/139/img-1_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/139/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/139/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/139/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/139/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/139/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/139/img-2_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/139/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/139/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/139/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/139/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/139/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"fotogallerie-2"} layout={"l8"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--1 pb--10 pt--10" columns={"1"}>
            
            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/139/img-3_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/139/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/139/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/139/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/139/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/139/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"fotogallerie-3"} layout={"l8"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--3 pb--10 pt--10" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/139/img-1_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/139/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/139/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/139/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/139/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/139/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/139/img-2_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/139/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/139/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/139/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/139/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/139/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/139/img-3_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/139/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/139/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/139/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/139/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/139/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"information-2"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--1 flex--top" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":560}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textes, die Schriftgröße und den Zeilenabstand ändern und sogar die maximale Breite des Textes in Pixeln einstellen."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" name={"partei"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--3 flex--center" anim={""} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-dominant);\">Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-dominant);\">Adriana<br><span style='font-style: italic;'>de</span> Colmé<br></span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-dominant);\">+49 797 811 2X05<br>info@vase-stranky.com</span>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--color-dominant);\">Firmen-Ident.-Nr.: 12345678<br>Erstellt mit <a href=\"https://saywebpage.com\">saywebpage.com</a></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}